export const getScanLink = (
	address: string,
	chainId: number,
	type: 'address' | 'tx' = 'address',
) => {
	switch (chainId) {
		case 1:
			return `https://etherscan.io/${type}/${address}`;
		case 56:
			return `https://bscscan.com/${type}/${address}`;
		case 137:
			return `https://polygonscan.com/${type}/${address}`;
		case 250:
			return `https://ftmscan.com/${type}/${address}`;
		case 43114:
			return `https://cchain.explorer.avax.network/${type}/${address}`;
		case 42161:
			return `https://arbiscan.io/${type}/${address}`;
		case 80001:
			return `https://explorer-mumbai.maticvigil.com/${type}/${address}`;
		case 122:
			return `https://explorer.fuse.io/${type}/${address}`;
		case 123:
			return `https://explorer.fusespark.io/${type}/${address}`;
		case 324:
			return `https://explorer.zksync.io/${type}/${address}`;
		case 280:
			return `https://zksync2-testnet.zkscan.io/${type}/${address}`;
		case 8453:
			return `https://basescan.org/${type}/${address}`;
		case 84531:
			return `https://goerli.basescan.org/${type}/${address}`;
		case 59144:
			return `https://lineascan.build/${type}/${address}`;
		case 534351:
			return `https://sepolia-blockscout.scroll.io/${type}/${address}`;
		case 169:
			return `https://pacific-explorer.manta.network/${type}/${address}`;
		case 3441005:
			return `https://pacific-explorer.testnet.manta.network/${type}/${address}`;
		case 204:
			return `https://opbnbscan.com/${type}/${address}`;
		case 34443:
			return `https://explorer.mode.network/${type}/${address}`;
		case 81457:
			return `https://blastscan.io/${type}/${address}`;
		case 1442:
			return `https://testnet-zkevm.polygonscan.com/${type}/${address}`;
		case 196:
			return `https://www.oklink.com/xlayer/${type}/${address}`;
		case 5000:
			return `https://explorer.mantle.xyz/${type}/${address}`;
		case 223:
			return `https://explorer.bsquared.network/${type}/${address}`;
		case 60808:
			return `https://explorer.gobob.xyz/${type}/${address}`;
		case 1030:
			return `https://evm.confluxscan.io/${type}/${address}`;
		case 9789:
			return `https://testnet.tabiscan.com/${type}/${address}`;
		case 255:
			return `https://kromascan.com/${type}/${address}`;
		case 30:
			return `https://explorer.rootstock.io/${type}/${address}`;
		case 5234:
			return `https://humanode.subscan.io/${type}/${address}`;
		case 185:
			return `https://explorer.mintchain.io/${type}/${address}`;
		case 88:
			return `https://vicscan.xyz/${type}/${address}`;
		case 80084:
			return `https://bartio.beratrail.io/${type}/${address}`;
		case 245022934:
			return `https://neonscan.org/${type}/${address}`;
		case 1329:
			return `https://seitrace.com/${type}/${address}?chain=pacific-1`;
		case 83144:
			return `https://explorer.testnet.xprotocol.org/${type}/${address}`;
		case 7000:
			return `https://explorer.zetachain.io/${type}/${address}`;
		case 40:
			return `https://teloscan.io/${type}/${address}`;
		case 1946:
			return `https://explorer-testnet.soneium.org/${type}/${address}`;
		case 388:
			return `https://explorer.zkevm.cronos.org/${type}/${address}`;
		case 70701:
			return `https://explorer.boss.proofofplay.com/${type}/${address}`;
		case 1116:
			return `https://scan.coredao.org/${type}/${address}`;
		case 11124:
			return `https://explorer.testnet.abs.xyz/${type}/${address}`;
		case 1301:
			return `https://sepolia.uniscan.xyz/${type}/${address}`;
		case 763373:
			return `https://explorer-sepolia.inkonchain.com/${type}/${address}`;
		case 33139:
			return `https://apechain.calderaexplorer.xyz/${type}/${address}`;
		case 167000:
			return `https://taikoscan.io/${type}/${address}`;
		case 57073:
			return `https://explorer.inkonchain.com/${type}/${address}`;
		case 17777:
			return `https://explorer.evm.eosnetwork.com/${type}/${address}`;
		case 2741:
			return `https://api.abscan.org/api/${type}/${address}`;
		case 98865:
			return `https://explorer.plumenetwork.xyz/${type}/${address}`;
		case 1868:
			return `https://soneium.blockscout.com/${type}/${address}`;
		case 11155111:
			return `https://sepolia.etherscan.io/${type}/${address}`;
		default:
			return '';
	}
};
